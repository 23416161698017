@import "support";

//Bike Slider Style
.slider-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .slider {
    display: flex;
    height: 100%;
  }

  .slide {
    position: relative;
    flex: 1 0 100%;
    background-size: cover;
    background-position: center;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .70);
  }

  .slide.slide-1 {
    background-image: url('../img/bike01.jpeg');
  }

  .slide.slide-2 {
    background-image: url('../img/bike02.jpeg');
  }

  .slide.slide-3 {
    background-image: url('../img/bike03.jpeg');
  }

  .slide.slide-4 {
    background-image: url('../img/bike04.jpeg');
  }

}

//Content and Graphic
#contentGraphicBlk {
  display: block;
  position: absolute;
  visibility: visible;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  ul.txtGraphicBlk {
    display: block;
    position: relative;
    height: 100%;

    li.txtGraphicBlk {
      display: inline-block;
      vertical-align: top;
      width: -webkit-calc(100% / 2);
      width: calc(100% / 2);
    }

    li.txtGraphicBlk:nth-child(1) {
      padding-top: 175px;

      .txtLine1 {
        display: block;
        font-family: 'arial-black';
        font-size: 2.4rem;
        line-height: 1.30em;
        margin-bottom: 20px;
        text-transform: capitalize;
        color: $blueDark;
      }

      .txtLine2 {
        display: block;
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 15px;
        color: $lightBlack;

        .txtLine2Bold {
          font-family: 'arial-black';
          color: $blueDark;
        }

        .txtLine2BoldOrangeBg {
          font-family: 'arial-black';
          color: $blueDark;
          padding: 0px 10px;
          border-radius: 5px;
          // background-color: rgba(236, 96, 36, .20);
        }
      }

      .graphicSmallScreen {
        display: none;
      }

      ul.btnBlk {
        display: block;
        margin-top: 30px;

        li.btnBlk {
          display: inline-block;
          vertical-align: top;
          position: relative;

          &:nth-last-child(2) {
            &::after {
              content: "Approval in 5 min";
              display: block;
              height: 25px;
              width: 60px;
              position: absolute;
              font-weight: bold;
              font-size: .85rem;
              color: $red;
              margin-top: 3px;
              left: 48%;
              transform: translateX(-50%);
              text-align: center;
            }
          }

          &:last-child {

            &::before,
            &::after {
              content: "";
              display: block;
              height: 25px;
              background-size: contain;
              background-repeat: no-repeat;
              left: 48%;
              transform: translateX(-50%);
            }

            &::before {
              width: 45px;
              position: absolute;
              top: -25px;
              background-image: url('../img/intOffered.svg');
            }

            &::after {
              width: 105px;
              position: absolute;
              bottom: -25px;
              background-image: url('../img/industryBest.svg');
            }
          }
        }

        .contact_button {
          padding: 10px 18px;
          margin-right: 8px;
          font-weight: bold;
          font-size: 1.2rem;
          color: $white;
          border: 0px;
          border-radius: 10px;
          background: $blue;
          outline: none;
          text-transform: capitalize;
        }

        .contact_button:hover {
          cursor: pointer;
          background: $blueDark;
        }
      }

      @media ($smaller-than: $breakpoint-799) {
        ul.btnBlk {
          margin-top: 25px;

          li.btnBlk {

            &:nth-last-child(2) {
              &::after {
                font-size: .7rem;
              }
            }

            &:last-child {

              &::before,
              &::after {
                content: "";
                height: 20px;
              }

              &::before {
                width: 37px;
                top: -20px;
              }

              &::after {
                width: 80px;
                bottom: -20px;
              }
            }
          }

          .contact_button {
            font-size: .9rem;
            border-radius: 8px;
            padding: 7px 12px;
            margin-right: 5px;
          }
        }
      }

    }

    li.txtGraphicBlk:nth-child(2) {
      height: 100%;
      background-image: url(../img/graphic.svg);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 650px;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.txtGraphicBlk:nth-child(1) {
        padding-top: 100px;

        .txtLine1 {
          font-size: 1.45rem;
          margin-bottom: 15px;
        }

        .txtLine2 {
          font-size: 1.2rem;
          margin-bottom: 5px;
        }
      }

      li.txtGraphicBlk:nth-child(2) {
        background-size: 420px;
      }
    }

    @media ($smaller-than: $breakpoint-450) {
      li.txtGraphicBlk {
        display: block;
        width: auto;
      }

      li.txtGraphicBlk:nth-child(2) {
        display: none;
      }

      li.txtGraphicBlk:nth-child(1) {
        padding-top: 70px;

        .txtLine1 {
          font-size: 1.25rem;
          margin-bottom: -5px;
        }

        .txtLine2 {
          font-size: 1rem;
        }

        .graphicSmallScreen {
          display: block;
          width: 100%;
          aspect-ratio: 1/1;
          background-image: url(../img/graphic_smScr.svg);
          background-position: center top;
          background-repeat: no-repeat;
          background-size: 280px;
          margin-bottom: -110px;
        }

        .btnBlk {

          .contact_button {
            font-size: .85rem;
            border-radius: 5px;
            padding: 6px 10px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

//Contact Home Bottom
#home_bottom_blk {
  display: block;
  visibility: visible;
  width: 100%;
  height: 100px;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 19;

  ul.botContactBlk {
    display: block;

    li.botContactBlk {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;
    }

    ul.botContactTxtIcon {
      display: block;

      li.botContactTxtIcon {
        display: inline-block;
        vertical-align: top;
      }

      li.botContactTxtIcon:nth-child(odd) {
        font-size: 1rem;
        font-weight: bold;
        color: $black;
        margin-right: 10px;
        text-align: right;

        .smallFont {
          font-size: .7rem;
        }
      }

      .phIcon,
      .linkIcon {
        height: 40px;
        aspect-ratio: 1/1;
        background-image: url(../img/phIcon.svg);
        background-position: left top;
        background-repeat: no-repeat;
      }

      .linkIcon {
        background-image: url(../img/linkIcon.svg);
        background-position: left top;
        background-repeat: no-repeat;
      }

      @media ($smaller-than: $breakpoint-799) {
        li.botContactTxtIcon:nth-child(odd) {
          font-size: .8rem;

          .smallFont {
            font-size: .7rem;
          }
        }

        .phIcon,
        .linkIcon {
          height: 30px;
        }
      }

      @media ($smaller-than: $breakpoint-399) {
        li.botContactTxtIcon:nth-child(odd) {
          font-size: .7rem;

          .smallFont {
            font-size: .6rem;
          }
        }

        .phIcon,
        .linkIcon {
          height: 25px;
        }
      }
    }
  }

  .disclaimerTxt {
    display: block;
    font-size: .8rem;
    margin-top: 15px;

    a {
      color: $black;
    }

    a:hover {
      color: $blue;
    }
  }

  .poweredTxt {
    display: block;
    font-size: .75rem;
    text-align: right;
    margin-top: 10px;
    color: $lightBlack;

    a {
      color: $black;
    }

    a:hover {
      color: $blue;
    }
  }

  @media ($smaller-than: $breakpoint-799) {
    .disclaimerTxt {
      font-size: .75rem;
      margin-top: 10px;
    }

    .poweredTxt {
      text-align: center;
      font-size: .65rem;
    }
  }

  @media ($smaller-than: $breakpoint-399) {
    .disclaimerTxt {
      font-size: .7rem;
      margin-top: 5px;
    }
  }
}

@media ($smaller-than: $breakpoint-799) {
  #home_bottom_blk {
    height: 80px;
  }
}

@media ($smaller-than: $breakpoint-399) {
  #home_bottom_blk {
    height: 60px;
  }
}

/*Bigger than 249px */
@media ($bigger-than: $breakpoint-249) {
  #home_bottom_blk {
    top: auto;
    bottom: 0px;
    position: absolute;
  }
}