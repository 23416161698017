@import "support";


/*Footer Common*/
#footer {
	clear: both;
	width: 100%;
	position: relative;
	z-index: 10;
	height: 30px;
	margin-top: -30px;
	background: $blueDark;

	.footer_blk {
		display: block;

		.footerInpage {
			display: block;
			line-height: 30px;
			text-align: center;
			font-size: 0.8rem;
			color: $white;
		}
	}
}

//Footer Home
#footer_home {
	background: $blueDark;
	padding: 50px 0px 30px 0px;
}

@media ($smaller-than: $breakpoint-1200) {
	#footer_home {
		padding: 15px 0px 0px 0px;
	}
}